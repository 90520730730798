<template>
  <main class="vcc-main" v-if="organization.id">
	<router-link :to="{ name: 'OrganizationList' }">← Назад</router-link><br><br>
	<h1 class="heading">{{ organization.organization_name }}</h1>
	<router-link :to="{ name: 'EditOrganization', params: { id: id } }">Редактировать</router-link><br><br>
	<organization-registry-form :form-data="organization" mode="read" :select-options="getFields"/>
  </main>
</template>

<script>
import OrganizationRegistryForm from '@/components/forms/OrganizationRegistryForm.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'OrganizationPage',
	components: { OrganizationRegistryForm },
	props: {
		id: {
			type: [String, Number],
			required: true,
		},
	},
	computed: {
		...mapGetters(['getRecord', 'getFields']),
		organization() {
			console.log(this.getRecord)
			return this.getRecord
		},
	},
	methods: {
		...mapActions(['fetchRecord', 'fetchFields', 'updateRecord']),
		submitForm(data) {
			this.updateRecord(data)
		},
	},
	created() {
		this.fetchFields()
		this.fetchRecord(this.id)
	}
}
</script>

<style>

</style>
